import React from 'react';
import './FormsPage.css';
import BreadcrumbComponent from "../../../components/BreadcrumbComponent/BreadcrumbComponent";
import {BsFileEarmarkPdfFill} from "react-icons/bs";
export default function FormsPage()
{
	return(
		<div className="forms-page">
			<div className="side"></div>
			<div className="content">
				 <BreadcrumbComponent activeLink="About / List of Forms"></BreadcrumbComponent>.pdf
				 <div className="sub-content">
				 	<ul className="form-ul">
				 		<li><a href="https://unnatbharatabhiyan.gov.in/public/uploads/forms_pdf/GPDP_report_format.pdf" target="_blank" rel="noreferrer" className="form-link"><span className="link-span">GPDP Report Format</span> <BsFileEarmarkPdfFill size="1.25rem" className="form-icon"/></a></li>
				 		<li><a href="https://unnatbharatabhiyan.gov.in/public/uploads/forms_pdf/sample_HOI_Letter.pdf" target="_blank" rel="noreferrer" className="form-link"><span className="link-span">Sample HOI Letter</span> <BsFileEarmarkPdfFill size="1.25rem" className="form-icon"/></a></li>
				 		<li><a href="https://unnatbharatabhiyan.gov.in/public/uploads/forms_pdf/UBA_Household_Survey_Form.pdf" target="_blank" rel="noreferrer" className="form-link"><span className="link-span">UBA Household Survey Form</span> <BsFileEarmarkPdfFill size="1.25rem" className="form-icon"/></a></li>
				 		<li><a href="https://unnatbharatabhiyan.gov.in/public/uploads/forms_pdf/UBA_mandate.pdf" target="_blank" rel="noreferrer" className="form-link"><span className="link-span">UBA Mandate Forms</span> <BsFileEarmarkPdfFill size="1.25rem" className="form-icon"/></a></li>
				 		<li><a href="https://unnatbharatabhiyan.gov.in/public/uploads/forms_pdf/UBA_Monthly_Progress_Report.pdf" target="_blank" rel="noreferrer" className="form-link"><span className="link-span">UBA Monthly Progress Report</span> <BsFileEarmarkPdfFill size="1.25rem" className="form-icon"/></a></li>
				 		<li><a href="https://unnatbharatabhiyan.gov.in/public/uploads/forms_pdf/UBA_Village_Survey_Form.pdf" target="_blank" rel="noreferrer" className="form-link"><span className="link-span">UBA Village Survey Form</span> <BsFileEarmarkPdfFill size="1.25rem" className="form-icon"/></a></li>
				 		<li><a href="https://unnatbharatabhiyan.gov.in/public/uploads/forms_pdf/Utilization-Certificate-Form-GFR-12A.pdf" target="_blank" rel="noreferrer" className="form-link"><span className="link-span">Utilization Certificate Form</span> <BsFileEarmarkPdfFill size="1.25rem" className="form-icon"/></a></li>
				 	</ul>
				 </div>
			</div>
		</div>
	);
}
